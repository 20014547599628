import Coachee from "module/coachee/domain/model/Coachee";

export default interface CoacheeResponse {
  id: string;
  firstName: string;
  lastName: string;
  teamIds: string[];
  companyId: string;
  note: string;
}

export function coacheeResponseToModel(coacheeResponse: CoacheeResponse): Coachee {
  return new Coachee(
    coacheeResponse.id,
    coacheeResponse.firstName,
    coacheeResponse.lastName,
    coacheeResponse.teamIds,
    coacheeResponse.companyId,
    coacheeResponse.note
  );
}

export function coacheesResponseToModel(coacheesResponse: CoacheeResponse[]): Coachee[] {
  return coacheesResponse.map(coacheesResponse => coacheeResponseToModel(coacheesResponse));
}
