import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import StatusIcon from "module/objective/view/component/StatusIcon";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import useDateTranslation from "asset/component/hook/useDateTranslation";
import ObjectiveStateHistoryComment from "module/objective/view/component/ObjectiveStateHistoryComment";
import {StateStatus} from "module/objective/domain/model/StateStatus";

const ObjectiveStateHistoryCommentSection = ({
  objectiveState,
  nextDate
} : {
  objectiveState? : ObjectiveState,
  nextDate : Date
}) => {
  const {t} = useTranslation("objective");
  
  const beginningDate = objectiveState?.createdAt.getDate();
  const endDate = nextDate.getDate();
  const translatedMonthAndYear = objectiveState && useDateTranslation().getMonthAndYearFromDate(objectiveState?.createdAt);
  const translatedDate = `${beginningDate} ${t('stateHistoryModal.toDate')} ${endDate} ${translatedMonthAndYear}`;
  
  const commentSection = () => {
    return objectiveState ?
    <div className={styles.commentSection}>
      <div className={styles.selectedState}>
        <StatusIcon status={objectiveState.status} />
        <div className={styles.selectedStateText}>
          <Typography fontSize={16}>{t(`state.${objectiveState.status}`)}</Typography>
        </div>
        <div className={styles.selectedStateDateLength}>
          <Typography fontSize={14}>{translatedDate}</Typography>
        </div>
      </div>
      <div className={styles.commentList}>
        <ObjectiveStateHistoryComment comment={objectiveState.comment} />
      </div>
    </div>
    : <></>
  };
  
  const renderObjectiveComment = () => {
    if (objectiveState && objectiveState.status !== StateStatus.NONE) {
      return commentSection()
    } else return <></>
  };
  
  return renderObjectiveComment();
}

export default ObjectiveStateHistoryCommentSection;
