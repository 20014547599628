import React from 'react';
import Mandate from "module/mandate/domain/model/Mandate";
import MandatePreviewComponent from "module/mandate/view/component/listing/MandatePreviewComponent";
import MandateObjectivesContainer from "module/objective/view/container/mandate/MandateObjectivesContainer";

const DUMMY_MANDATE = new Mandate("1", "title", "description", new Date());

const MandatePreviewContainer = ({
  mandate = DUMMY_MANDATE,
  isDialogOpen = false,
  close
}: {
  mandate?: Mandate,
  isDialogOpen?: boolean,
  close: () => void
}) => {
  
  return (
    <MandatePreviewComponent isDialogOpen={isDialogOpen}
                             mandate={mandate}
                             close={close}
                             objectives={<MandateObjectivesContainer isEditable={false}
                                                                     mandateId={mandate.id} />} />
  );
};

export default MandatePreviewContainer;
