import AddMandate from "module/mandate/domain/model/AddMandate";

export default interface AddMandateRequest {
  title: string;
  description: string;
  deadline: string;
}

export function createAddMandateRequest(addMandate: AddMandate): AddMandateRequest {
  return {
    title: addMandate.title,
    description: addMandate.description,
    deadline: addMandate.deadline.toISOString(),
  } as AddMandateRequest;
}
