import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import styles from "module/mandate/view/asset/add/AddMandateInfoFormComponent.module.scss";
import formStyles from "module/mandate/view/asset/add/inputs/MandateInputs.module.scss";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment/moment";

const MandateDatePicker = ({
  date,
  setDate,
  placeholder,
  hasLabel = true
}: {
  date: Moment | null,
  setDate: (date: Moment | null) => void,
  placeholder?: string,
  hasLabel?: boolean
}) => {
  const {t, i18n} = useTranslation('mandate');
  
  React.useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  
  return (
    <div className={styles.inputContainer}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
        <DatePicker label={placeholder}
                    value={date ? date : moment().add(1, 'year')}
                    onChange={(date) => setDate(date)}
                    className={styles.datePicker}
                    format={'DD MMM YYYY'}
                    disablePast={true} />
      </LocalizationProvider>
    </div>
  );
};

export default MandateDatePicker;
