export interface IAddMandate {
  title: string;
  description: string;
  deadline: Date;
}

export default class AddMandate implements IAddMandate {
  private readonly _title: string;
  private readonly _description: string;
  private readonly _deadline: Date;
  
  constructor(title: string, description: string, deadline: Date = AddMandate.getDateOneYearFromNow()) {
    this._title = title;
    this._description = description;
    this._deadline = deadline;
  }
  
  // make a method to get the date one year from now
  private static getDateOneYearFromNow(): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }
  
  get title(): string {
    return this._title;
  }
  
  get description(): string {
    return this._description;
  }
  
  get deadline(): Date {
    return this._deadline;
  }
}
