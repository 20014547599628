import React, {useState} from 'react';
import AddMandateFormComponent, {
  AddMandateStateProps
} from "module/mandate/view/component/add/AddMandateFormComponent";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import FormHeader from "asset/component/form/FormHeader";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import {Moment} from "moment/moment";
import {Routes} from "router/Routes";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const AddMandateFormContainer = () => {
  const dispatch = useDispatch<Dispatch>();
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState<Moment | null>(null);
  const [currentMandateId, setCurrentMandateId] = useState<string>("");
  
  const addMandateState = {
    title,
    setTitle,
    description,
    setDescription,
    deadline,
    setDeadline
  } as AddMandateStateProps;
  
  const [addMandateLoading, addMandateErrorName, addMandateDispatch]
    = usePromiseLoadingAndError((newMandate) =>
    dispatch.MandateStore.addMandate({
        addMandate: newMandate
      })
      .then((mandate) => setCurrentMandateId(mandate.id))
  );
  
  return (<>
    <HadAccess accesses={[Accesses.MANDATES_MANAGEMENT]}>
      <BodySectionLayout>
        <FormHeader navigateTo={Routes.mandate.path()} />
        <AddMandateFormComponent addMandateState={addMandateState}
                                 submitForm={(newMandate) => addMandateDispatch(newMandate)}
                                 currentMandateId={currentMandateId}
                                 errorName={addMandateErrorName}
                                 isLoading={addMandateLoading}
        />
      </BodySectionLayout>
    </HadAccess>
  </>);
};

export default AddMandateFormContainer;
