import type RESTClient from "core/client/RESTClient";
import ObjectiveTeamRepository from "module/objective/domain/ObjectiveTeamRepository";
import {
  objectivesTeamResponseToModel,
  ObjectiveTeamResponse,
  objectiveTeamResponseToModel,
} from "module/objective/dal/dto/ObjectiveResponse";
import ObjectiveTeam from "module/objective/domain/model/ObjectiveTeam";
import AddObjective from "module/objective/domain/model/AddObjective";
import {createAddObjectiveRequest} from "module/objective/dal/dto/AddObjectiveRequest";
import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import SubObjective from "module/objective/domain/model/SubObjective";
import SubObjectiveResponse, {subObjectiveResponseToModel} from "module/objective/dal/dto/SubObjectiveResponse";
import {createAddSubObjectiveRequest} from "module/objective/dal/dto/AddSubObjectiveRequest";
import {createUpdateSubObjectiveRequest} from "module/objective/dal/dto/UpdateSubObjectiveRequest";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import {createUpdateObjectiveTeamRequest} from "module/objective/dal/dto/UpdateObjectiveTeamRequest";
import NeedStats from "module/objective/domain/model/need/NeedStats";
import {objectiveNeedsStatsResponseToModel} from "module/objective/dal/dto/ObjectiveNeedsStatsResponse";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import ObjectiveStateResponse, {
  objectiveStateResponseToModel,
  objectiveStatesResponseToModel
} from "module/objective/dal/dto/ObjectiveStateResponse";
import {createAddObjectiveStateRequest} from "module/objective/dal/dto/AddObjectiveStateRequest";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";
import {createUpdateObjectiveStateCommentRequest} from "module/objective/dal/dto/UpdateObjectiveStateCommentRequest";

export default class RESTObjectiveTeamRepository implements ObjectiveTeamRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly TEAM_URL: string = "teams";
  private static readonly OBJECTIVE_URL: string = "objectives";
  private static readonly SUB_OBJECTIVE_URL: string = "subObjectives";
  private static readonly STATS_URL: string = "stats";
  private static readonly OBJECTIVE_STATE_HISTORY_URL: string = "objectiveStateHistory";
  
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchObjectivesByTeamId(id: string, need?: NeedsTeam): Promise<ObjectiveTeam[]> {
    const query = need ? `?need=${need}` : "";
    
    const objectivesTeamResponse = await this.client.apiGet<ObjectiveTeamResponse[]>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${id}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}` +
      query
    );
    
    return objectivesTeamResponseToModel(objectivesTeamResponse, id);
  }
  
  public async addObjectiveForTeam(addObjective: AddObjective, teamId: string): Promise<ObjectiveTeam> {
    const objectiveTeamResponse = await this.client.apiPost<ObjectiveTeamResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}`,
      createAddObjectiveRequest(addObjective)
    );
    
    return objectiveTeamResponseToModel(objectiveTeamResponse, teamId);
  }
  
  public async updateObjectiveForTeam(
    updateObjective: UpdateObjective,
    objectiveId: string,
    teamId: string
  ): Promise<ObjectiveTeam> {
    const objectiveTeamResponse = await this.client.apiPut<ObjectiveTeamResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}`,
      createUpdateObjectiveTeamRequest(updateObjective)
    );
    
    return objectiveTeamResponseToModel(objectiveTeamResponse, teamId);
  }
  
  public async deleteObjectiveForTeam(objectiveId: string, teamId: string): Promise<void> {
    await this.client.apiDelete<ObjectiveTeamResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}`
    );
  }
  
  public async addSubObjectiveForTeam(
    addSubObjective: AddSubObjective,
    objectiveId: string,
    teamId: string
  ): Promise<SubObjective> {
    const subObjectiveResponse = await this.client.apiPost<SubObjectiveResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveTeamRepository.SUB_OBJECTIVE_URL}`,
      createAddSubObjectiveRequest(addSubObjective)
    );
    
    return subObjectiveResponseToModel(subObjectiveResponse);
  }
  
  public async updateSubObjectiveForTeam(
    updateSubObjective: UpdateSubObjective,
    subObjectiveId: string,
    objectiveId: string,
    teamId: string
  ): Promise<SubObjective> {
    const subObjectiveResponse = await this.client.apiPut<SubObjectiveResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveTeamRepository.SUB_OBJECTIVE_URL}/${subObjectiveId}`,
      createUpdateSubObjectiveRequest(updateSubObjective)
    );
    
    return subObjectiveResponseToModel(subObjectiveResponse);
  }
  
  public async deleteSubObjectiveForTeam(
    subObjectiveId: string,
    objectiveId: string,
    teamId: string
  ): Promise<void> {
    await this.client.apiDelete<SubObjectiveResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveTeamRepository.SUB_OBJECTIVE_URL}/${subObjectiveId}`
    );
  }
  
  public async fetchObjectiveNeedsStatsByNeed(teamId: string, need: NeedsTeam): Promise<NeedStats> {
    const query = need ? `?need=${need}` : "";
    
    const needStatsResponse = await this.client.apiGet<NeedStats>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${RESTObjectiveTeamRepository.STATS_URL}` +
      query
    );
    
    return objectiveNeedsStatsResponseToModel(needStatsResponse);
  }
  
  public async addObjectiveStateForTeam(
    addObjectiveState: AddObjectiveState,
    teamId: string,
    objectiveId: string
  ): Promise<ObjectiveState> {
    const objectiveStateResponse = await this.client.apiPost<ObjectiveStateResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_STATE_HISTORY_URL}`,
      createAddObjectiveStateRequest(addObjectiveState)
    );
    return objectiveStateResponseToModel(objectiveStateResponse);
  }
  
  public async updateObjectiveStateCommentForTeam(
    updateObjectiveStateComment: UpdateObjectiveStateComment,
    coacheeId: string,
    objectiveId: string
  ): Promise<ObjectiveState> {
    const objectiveStateResponse = await this.client.apiPut<ObjectiveStateResponse>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${coacheeId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_STATE_HISTORY_URL}`,
      createUpdateObjectiveStateCommentRequest(updateObjectiveStateComment)
    )
    
    return objectiveStateResponseToModel(objectiveStateResponse);
  }
  
  public async fetchObjectiveStateHistory(teamId: string, objectiveId: string): Promise<ObjectiveState[]> {
    const objectiveStateHistoryResponse = await this.client.apiGet<ObjectiveStateResponse[]>(
      `/${RESTObjectiveTeamRepository.BASE_URL}` +
      `/${RESTObjectiveTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveTeamRepository.OBJECTIVE_STATE_HISTORY_URL}`
    );
    return objectiveStatesResponseToModel(objectiveStateHistoryResponse)
  }
}
