import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import ObjectiveComponent from "module/objective/view/component/ObjectiveComponent";
import ObjectiveCoachee from "module/objective/domain/model/ObjectiveCoachee";
import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import CoacheeSubObjectiveContainer from "module/objective/view/container/coachee/CoacheeSubObjectiveContainer";
import SubObjective from "module/objective/domain/model/SubObjective";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import {Tag} from "asset/component/tag/Tag";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import TagServices from "asset/service/TagServices";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";

const CoacheeObjectiveContainer = ({objectiveCoachee}: {
  objectiveCoachee: ObjectiveCoachee
}) => {
  const dispatch = useDispatch<Dispatch>();
  const completedSubObjectiveCount = useSelector((rootState: RootState) =>
    rootState.ObjectiveCoacheeStore?.getters(rootState).getCompletedSubObjectivesCoacheesCount(objectiveCoachee.id));
  
  const [isDeleteObjectiveLoading, deleteObjectiveErrorName, dispatchDeleteObjective] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveCoacheeStore.deleteObjectiveForCoachee({
        objectiveId: objectiveCoachee.id,
        coacheeId: objectiveCoachee.coacheeId,
      })
    );
  
  const [isUpdateObjectiveLoading, updateObjectiveErrorName, dispatchUpdateObjective] =
    usePromiseLoadingAndError(({name, text, needs}) =>
      dispatch.ObjectiveCoacheeStore.updateObjectiveForCoachee({
        objectiveId: objectiveCoachee.id,
        coacheeId: objectiveCoachee.coacheeId,
        updateObjective: new UpdateObjective(
          name || objectiveCoachee.name,
          text || objectiveCoachee.text,
          needs || objectiveCoachee.needs
        )
      })
    );
  
  const [isAddSubObjectiveLoading, addSubObjectiveErrorName, dispatchAddSubObjective] =
    usePromiseLoadingAndError((name) =>
      dispatch.ObjectiveCoacheeStore.addSubObjectiveForCoachee({
        objectiveId: objectiveCoachee.id,
        coacheeId: objectiveCoachee.coacheeId,
        addSubObjective: new AddSubObjective(name)
      })
    );
  
  const [isAddObjectiveStateLoading, addObjectiveStateErrorName, dispatchAddObjectiveState] =
    usePromiseLoadingAndError((status, comment) =>
        dispatch.ObjectiveCoacheeStore.addObjectiveState({
          addObjectiveState: new AddObjectiveState(status),
          objectiveId: objectiveCoachee.id,
          coacheeId: objectiveCoachee.coacheeId,
        })
    );
  
  const [isUpdateObjectiveStateLoading, updateObjectiveStateErrorName, dispatchUpdateObjectiveState] =
    usePromiseLoadingAndError((comment) =>
      dispatch.ObjectiveCoacheeStore.updateObjectiveStateComment({
        updateObjectiveStateComment: new UpdateObjectiveStateComment(comment),
        objectiveId: objectiveCoachee.id,
        coacheeId: objectiveCoachee.coacheeId,
      })
    );
  
  const [isHistoryLoading, fetchHistoryError, fetchObjectiveStateHistory] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveCoacheeStore.fetchObjectiveStateHistory({
        coacheeId: objectiveCoachee.coacheeId,
        objectiveId: objectiveCoachee.id
      })
    );
    
  const objectiveTagManager =
    new TagServices<NeedsCoachee>("common:coacheeNeeds", Object.values(NeedsCoachee));
  
  const addTag = (tag: Tag) => {
    dispatchUpdateObjective({needs: [...objectiveCoachee.needs, tag.id]});
  };
  
  const removeTag = (tag: Tag) => {
    dispatchUpdateObjective({needs: objectiveCoachee.needs.filter(need => need !== tag.id)});
  };
  
  const tags = (
    <TagDisplayer
      skipEditModeStep={false}
      tags={objectiveTagManager.buildTags(objectiveCoachee.needs, true)}
      predefinedTags={objectiveTagManager.buildFilteredPredefinedTagNames(objectiveCoachee.needs)}
      addTag={addTag}
      removeTag={removeTag}
    />
  );
  
  return (
    <ObjectiveComponent key={objectiveCoachee.id}
                        data-testid={`objective_${objectiveCoachee.id}`}
                        objective={objectiveCoachee}
                        completedSubObjectiveCount={completedSubObjectiveCount}
                        isLoading={isDeleteObjectiveLoading}
                        isUpdateLoading={isUpdateObjectiveLoading}
                        errorName={deleteObjectiveErrorName || updateObjectiveErrorName}
                        setName={(name) => dispatchUpdateObjective({name})}
                        setText={(text) => dispatchUpdateObjective({text})}
                        addSubObjective={dispatchAddSubObjective}
                        isAddSubObjectiveLoading={isAddSubObjectiveLoading}
                        addSubObjectiveErrorName={addSubObjectiveErrorName}
                        deleteObjective={dispatchDeleteObjective}
                        addObjectiveState={dispatchAddObjectiveState}
                        updateObjectiveState={dispatchUpdateObjectiveState}
                        fetchObjectiveStateHistory={fetchObjectiveStateHistory}
                        isStateHistoryLoading={isHistoryLoading}
                        tags={tags}>
      {
        (subObjective: SubObjective) => <CoacheeSubObjectiveContainer key={subObjective.id}
                                                                      coacheeId={objectiveCoachee.coacheeId}
                                                                      objectiveId={objectiveCoachee.id}
                                                                      subObjective={subObjective} />
      }
    </ObjectiveComponent>
  );
};

export default CoacheeObjectiveContainer;
