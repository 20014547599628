import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import ObjectiveComponent from "module/objective/view/component/ObjectiveComponent";
import ObjectiveTeam from "module/objective/domain/model/ObjectiveTeam";
import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import TeamSubObjectiveContainer from "module/objective/view/container/team/TeamSubObjectiveContainer";
import SubObjective from "module/objective/domain/model/SubObjective";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import {Tag} from "asset/component/tag/Tag";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import TagServices from "asset/service/TagServices";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";

const TeamObjectiveContainer = ({objectiveTeam}: {
  objectiveTeam: ObjectiveTeam
}) => {
  const dispatch = useDispatch<Dispatch>();
  const completedSubObjectiveCount = useSelector((rootState: RootState) =>
    rootState.ObjectiveTeamStore?.getters(rootState).getCompletedSubObjectivesTeamsCount(objectiveTeam.id));
  
  const [isDeleteObjectiveLoading, deleteObjectiveErrorName, dispatchDeleteObjective] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveTeamStore.deleteObjectiveForTeam({
        objectiveId: objectiveTeam.id,
        teamId: objectiveTeam.teamId,
      })
    );
  
  const [isUpdateObjectiveLoading, updateObjectiveErrorName, dispatchUpdateObjective] =
    usePromiseLoadingAndError(({name, text, needs}) =>
      dispatch.ObjectiveTeamStore.updateObjectiveForTeam({
        objectiveId: objectiveTeam.id,
        teamId: objectiveTeam.teamId,
        updateObjective: new UpdateObjective(
          name || objectiveTeam.name,
          text || objectiveTeam.text,
          needs || objectiveTeam.needs
        )
      })
    );
  
  const [isAddSubObjectiveLoading, addSubObjectiveErrorName, dispatchAddSubObjective] =
    usePromiseLoadingAndError((name) =>
      dispatch.ObjectiveTeamStore.addSubObjectiveForTeam({
        objectiveId: objectiveTeam.id,
        teamId: objectiveTeam.teamId,
        addSubObjective: new AddSubObjective(name)
      })
    );
  
  const [isAddObjectiveStateLoading, addObjectiveStateErrorName, dispatchAddObjectiveState] =
    usePromiseLoadingAndError((status) =>
      dispatch.ObjectiveTeamStore.addObjectiveState({
        addObjectiveState: new AddObjectiveState(status),
        objectiveId: objectiveTeam.id,
        teamId: objectiveTeam.teamId,
      })
    );
  
  const [isUpdateObjectiveStateLoading, updateObjectiveStateErrorName, dispatchUpdateObjectiveState] =
    usePromiseLoadingAndError((comment) =>
      dispatch.ObjectiveTeamStore.updateObjectiveStateComment({
        updateObjectiveStateComment: new UpdateObjectiveStateComment(comment),
        objectiveId: objectiveTeam.id,
        teamId: objectiveTeam.teamId,
      })
    );
  
  const [isHistoryLoading, fetchHistoryError, fetchObjectiveStateHistory] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveTeamStore.fetchObjectiveStateHistory({
        teamId: objectiveTeam.teamId,
        objectiveId: objectiveTeam.id
      })
    );
  
  const objectiveTagManager =
    new TagServices<NeedsTeam>("common:teamNeeds", Object.values(NeedsTeam));
  
  const addTag = (tag: Tag) => {
    dispatchUpdateObjective({needs: [...objectiveTeam.needs, tag.id]});
  };
  
  const removeTag = (tag: Tag) => {
    dispatchUpdateObjective({needs: objectiveTeam.needs.filter(need => need !== tag.id)});
  };
  
  const tags = (
    <TagDisplayer
      skipEditModeStep={false}
      tags={objectiveTagManager.buildTags(objectiveTeam.needs, true)}
      predefinedTags={objectiveTagManager.buildFilteredPredefinedTagNames(objectiveTeam.needs)}
      addTag={addTag}
      removeTag={removeTag}
    />
  );
  
  return (
    <ObjectiveComponent key={objectiveTeam.id}
                        data-testid={`objective_${objectiveTeam.id}`}
                        objective={objectiveTeam}
                        completedSubObjectiveCount={completedSubObjectiveCount}
                        isLoading={isDeleteObjectiveLoading}
                        isUpdateLoading={isUpdateObjectiveLoading}
                        errorName={deleteObjectiveErrorName || updateObjectiveErrorName}
                        setName={(name) => dispatchUpdateObjective({name})}
                        setText={(text) => dispatchUpdateObjective({text})}
                        addSubObjective={dispatchAddSubObjective}
                        isAddSubObjectiveLoading={isAddSubObjectiveLoading}
                        addSubObjectiveErrorName={addSubObjectiveErrorName}
                        deleteObjective={dispatchDeleteObjective}
                        addObjectiveState={dispatchAddObjectiveState}
                        updateObjectiveState={dispatchUpdateObjectiveState}
                        fetchObjectiveStateHistory={fetchObjectiveStateHistory}
                        isStateHistoryLoading={isHistoryLoading}
                        tags={tags}>
      {
        (subObjective: SubObjective) => <TeamSubObjectiveContainer key={subObjective.id}
                                                                   teamId={objectiveTeam.teamId}
                                                                   objectiveId={objectiveTeam.id}
                                                                   subObjective={subObjective} />
      }
    </ObjectiveComponent>
  );
};

export default TeamObjectiveContainer;
