import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import {useTranslation} from "react-i18next";
import {CircularProgress, Typography} from "@mui/material";
import ObjectiveStateHistoryTable from "module/objective/view/component/ObjectiveStateHistoryTable";
import {useEffect, useState} from "react";
import ObjectiveStateHistoryCommentSection from "module/objective/view/component/ObjectiveStateHistoryCommentSection";
import ObjectiveStateHistoryControls from "module/objective/view/component/ObjectiveStateHistoryControls";
import {useSelector} from "react-redux";
import {RootState} from "store";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";

export enum StateHistoryInterval {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

export enum IntervalMovement {
  FORWARD,
  BACKWARD
}

const ObjectiveStateHistoryModal = () => {
  const {t} = useTranslation("objective");
  
  const [intervalTypeSelected, setHistoryIntervalType] = useState(StateHistoryInterval.MONTH);
  const [dateSelected, setDateSelected] = useState(new Date(Date.now()));
  const [filteredHistory, setFilteredHistory] = useState<ObjectiveState[]>([]);
  
  const filterHistoryByMonth = (dateToFilter) => {
    return objectiveStateHistory.filter(state => state.createdAt.getMonth() === dateToFilter.getMonth());
  }
  
  const objectiveStateHistory = useSelector((rootState: RootState) =>
    rootState.ObjectiveCoacheeStore?.getters(rootState).getObjectiveStateHistory()
  );
  
  const [objectiveStateDisplayed, setObjectiveStateDisplayed] =
    useState(objectiveStateHistory.at(objectiveStateHistory.length - 1));
  
  useEffect(() => {
    setFilteredHistory(filterHistoryByMonth(dateSelected))
  }, [dateSelected])
  
  const moveInterval = (movement: IntervalMovement) => {
    let newDate = new Date(dateSelected);
    switch (intervalTypeSelected) {
      case StateHistoryInterval.MONTH:
        if (movement === IntervalMovement.FORWARD) {
          newDate.setMonth(newDate.getMonth() + 1);
        } else newDate.setMonth(newDate.getMonth() - 1);
        break;
      case StateHistoryInterval.WEEK:
        if (movement === IntervalMovement.FORWARD) {
          newDate.setDate(newDate.getDate() + 7);
        } else newDate.setDate(newDate.getDate() - 7);
        break;
      case StateHistoryInterval.YEAR:
        if (movement === IntervalMovement.FORWARD) {
          newDate.setFullYear(newDate.getFullYear() + 1);
        } else newDate.setFullYear(newDate.getFullYear() - 1);
        break;
    }
    setDateSelected(newDate);
  }
  
  return(
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography fontSize={18} align={"center"}>{t("stateHistoryModal.containerTitle")}</Typography>
      </div>
      <ObjectiveStateHistoryControls
        intervalSelected={dateSelected}
        moveInterval={moveInterval}
        intervalTypeSelected={intervalTypeSelected}
        setIntervalType={setHistoryIntervalType}/>
      <ObjectiveStateHistoryTable currentDate={dateSelected} stateHistoryForCurrentInterval={filteredHistory}/>
      <ObjectiveStateHistoryCommentSection objectiveState={objectiveStateDisplayed} nextDate={new Date(Date.now())}/>
    </div>
  )
}

export default ObjectiveStateHistoryModal;
