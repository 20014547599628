import React from 'react';
import Mandate from "module/mandate/domain/model/Mandate";
import Hourglass from "asset/icon/Hourglass";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import styles from "module/mandate/view/asset/edit/MandateDeadlineEdit.module.scss";
import MandateDatePicker from "module/mandate/view/component/add/inputs/MandateDatePicker";
import moment, {Moment} from "moment/moment";

const MandateDeadlineEdit = ({
  mandate,
  setDeadline
}: {
  mandate?: Mandate | null,
  setDeadline: (date: Moment | null) => void
}) => {
  const {t} = useTranslation('mandate');
  
  return (
    <div className={styles.deadline}>
      <div className={styles.title}>
        <Hourglass />
        <Typography component="p" className={styles.date}>
          {t('form.edit.date.end')} :
        </Typography>
      </div>
      {mandate && mandate.deadline ?
        <div className={styles.datePicker}>
          <MandateDatePicker hasLabel={false} date={moment.utc(mandate.deadline)} setDate={setDeadline} />
        </div>
        :
        <></>
      }
    </div>
  );
};

export default MandateDeadlineEdit;
