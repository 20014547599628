import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import HorizontalSeparatorLine, {SeparatorColor} from "asset/component/module/HorizontalSeparatorLine";
import useDateUtils from "asset/component/hook/useDateUtils";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";
import {Typography} from "@mui/material";

const ObjectiveStateHistoryTable = ({
  currentDate,
  stateHistoryForCurrentInterval
} : {
  currentDate: Date,
  stateHistoryForCurrentInterval: ObjectiveState[]
}) => {
  
  const numberOfDays = useDateUtils().getAmountOfDaysInMonth(currentDate.getFullYear(), currentDate.getMonth())
  const arraysOfDays = Array.from(Array(numberOfDays).keys())
  
  const datesBottomOfTable = () =>
    arraysOfDays.map((item, index) =>
      <div className={styles.tableCell} key={index}>
        <Typography align={"center"}>
          {item + 1}
        </Typography>
      </div>
    )
  
  const stateMatchesDate = (state: ObjectiveState, date:number) => {
    return state.createdAt.getDate() === date
  }
  
  const getCellsOfRow = (statusType:StateStatus) => {
    const historyByType = stateHistoryForCurrentInterval.filter(state => state.status === statusType);
    return arraysOfDays.map((item, index) => {
      for (let state in historyByType) {
        if (stateMatchesDate(historyByType[state], item + 1))
          return <div className={styles.tableCell} key={index}>
            <StatusIcon status={statusType} size={MuiSizes.SMALL} />
        </div>
      }
      return <div className={styles.tableCell} key={index}/>;
    })
  }
  
  return <div className={styles.mainTable}>
    <div className={styles.verticalLine}>
        <div className={styles.row}>
          {getCellsOfRow(StateStatus.OPTIMAL)}
        </div>
        <div className={styles.row}>
          {getCellsOfRow(StateStatus.FINE)}
        </div>
        <div className={styles.row}>
          {getCellsOfRow(StateStatus.IDLE)}
        </div>
        <div className={styles.row}>
          {getCellsOfRow(StateStatus.MONITORING)}
        </div>
      </div>
      <HorizontalSeparatorLine color={SeparatorColor.BLACK} />
      <div className={styles.timeInterval}>
        {datesBottomOfTable()}
      </div>
    </div>;
}

export default ObjectiveStateHistoryTable;
