import React, {useRef} from 'react';
import {TextValidator, useFormValidation, ValidatorForm} from "asset/validator";
import MuiInputSizes from "style/props/MuiInputSizes";
import {CircularProgress} from "@mui/material";
import styles from "asset/component/asset/form/inputs/InputAutoUpdate.module.scss";
import SaveState from "asset/component/form/SaveState";
import useAutoSave from "asset/component/hook/useAutoSave";

interface InputButtonProps {
  id?: string;
  initialValue: string;
  title?: string | JSX.Element;
  saveAction: (text: string) => void;
  errorMessage?: string;
  isLoading?: boolean;
  placeholder?: string;
  validators?: string[];
  errorValidationMessages?: string[];
  rows?: number;
  maxlength?: number;
}

const InputAutoUpdate: React.FC<InputButtonProps> = ({
  id,
  title,
  initialValue,
  saveAction,
  isLoading,
  errorMessage,
  placeholder,
  validators,
  errorValidationMessages,
  maxlength,
  rows = 0
}) => {
  const formRef = useRef<HTMLFormElement>();
  const {isFormValid} = useFormValidation(formRef, []);
  const isMultiline = rows > 0;
  
  const {value, isSaving, isError, onChange} = useAutoSave({initialValue, saveAction});
  
  return (
    <ValidatorForm id={`${id}-form`}
                   className={styles.form}
                   data-testid={`${id}-form`}
                   ref={formRef}
                   onSubmit={() => saveAction(value)}
                   onBlur={() => isFormValid && formRef?.current?.props.onSubmit()}>
      <div className={styles.labels}>
        {title && <div>{title}</div>}
        {isMultiline && maxlength && <div className={styles.lengthCount}>{value.length}/{maxlength}</div>}
      </div>
      <TextValidator
        data-testid={`${id}-input`}
        id={id}
        className={styles.input}
        maxRows={rows}
        minRows={rows}
        multiline={isMultiline}
        size={MuiInputSizes.LARGE}
        fullWidth
        placeholder={placeholder}
        value={value}
        onChange={(content) => onChange(content.target.value)}
        validators={validators}
        errorMessages={errorValidationMessages || errorMessage}
        inputProps={{maxLength: maxlength}}
        InputProps={{endAdornment: isLoading && <CircularProgress data-testid={`${id}-loading`} size={20} />}}
      />
      <SaveState isSaving={isSaving}
                 isError={isError}
                 isSaved={!isError && !isSaving}
      />
    </ValidatorForm>
  );
};

export default InputAutoUpdate;
