import type RESTClient from "core/client/RESTClient";
import ObjectiveMandateRepository from "module/objective/domain/ObjectiveMandateRepository";
import ObjectiveResponse, {
  objectiveMandateResponseToModel,
  objectivesMandateResponseToModel,
  ObjectiveMandateResponse
} from "module/objective/dal/dto/ObjectiveResponse";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import SubObjective from "module/objective/domain/model/SubObjective";
import SubObjectiveResponse, {subObjectiveResponseToModel} from "module/objective/dal/dto/SubObjectiveResponse";
import {createAddSubObjectiveRequest} from "module/objective/dal/dto/AddSubObjectiveRequest";
import {createUpdateSubObjectiveRequest} from "module/objective/dal/dto/UpdateSubObjectiveRequest";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import {createAddObjectiveMandateRequest} from "module/objective/dal/dto/AddObjectiveMandateRequest";
import AddObjectiveMandate from "module/objective/domain/model/AddObjectiveMandate";
import UpdateObjectiveMandate from "module/objective/domain/model/UpdateObjectiveMandate";
import ObjectiveMandate from "module/objective/domain/model/ObjectiveMandate";
import {createUpdateObjectiveMandateRequest} from "module/objective/dal/dto/UpdateObjectiveMandateRequest";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import ObjectiveStateResponse, {
  objectiveStateResponseToModel,
  objectiveStatesResponseToModel
} from "module/objective/dal/dto/ObjectiveStateResponse";
import {createAddObjectiveStateRequest} from "module/objective/dal/dto/AddObjectiveStateRequest";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";
import {createUpdateObjectiveStateCommentRequest} from "module/objective/dal/dto/UpdateObjectiveStateCommentRequest";

export default class RESTObjectiveMandateRepository implements ObjectiveMandateRepository {
  public static readonly BASE_URL: string = "api";
  public static readonly MANDATE_URL: string = "mandates";
  public static readonly OBJECTIVE_URL: string = "objectives";
  public static readonly SUB_OBJECTIVE_URL: string = "subObjectives";
  private static readonly OBJECTIVE_STATE_HISTORY_URL: string = "objectiveStateHistory";
  
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchObjectivesByMandateId(id: string): Promise<ObjectiveMandate[]> {
    const objectivesMandateResponse = await this.client.apiGet<ObjectiveMandateResponse[]>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${id}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}`
    );
    
    return objectivesMandateResponseToModel(objectivesMandateResponse, id);
  }
  
  public async addObjectiveForMandate(
    addObjectiveMandate: AddObjectiveMandate,
    mandateId: string
  ): Promise<ObjectiveMandate> {
    const objectiveMandateResponse = await this.client.apiPost<ObjectiveMandateResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}`,
      createAddObjectiveMandateRequest(addObjectiveMandate)
    );
    
    return objectiveMandateResponseToModel(objectiveMandateResponse, mandateId);
  }
  
  public async updateObjectiveForMandate(
    updateObjectiveMandate: UpdateObjectiveMandate,
    objectiveId: string,
    mandateId: string
  ): Promise<ObjectiveMandate> {
    const objectiveMandateResponse = await this.client.apiPut<ObjectiveMandateResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}`,
      createUpdateObjectiveMandateRequest(updateObjectiveMandate)
    );
    
    return objectiveMandateResponseToModel(objectiveMandateResponse, mandateId);
  }
  
  public async deleteObjectiveForMandate(objectiveId: string, mandateId: string): Promise<void> {
    await this.client.apiDelete<ObjectiveResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}`
    );
  }
  
  public async addSubObjectiveForMandate(
    addSubObjective: AddSubObjective,
    objectiveId: string,
    mandateId: string
  ): Promise<SubObjective> {
    const subObjectiveResponse = await this.client.apiPost<SubObjectiveResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveMandateRepository.SUB_OBJECTIVE_URL}`,
      createAddSubObjectiveRequest(addSubObjective)
    );
    
    return subObjectiveResponseToModel(subObjectiveResponse);
  }
  
  public async updateSubObjectiveForMandate(
    updateSubObjective: UpdateSubObjective,
    subObjectiveId: string,
    objectiveId: string,
    mandateId: string
  ): Promise<SubObjective> {
    const subObjectiveResponse = await this.client.apiPut<SubObjectiveResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveMandateRepository.SUB_OBJECTIVE_URL}/${subObjectiveId}`,
      createUpdateSubObjectiveRequest(updateSubObjective)
    );
    
    return subObjectiveResponseToModel(subObjectiveResponse);
  }
  
  public async deleteSubObjectiveForMandate(
    subObjectiveId: string,
    objectiveId: string,
    mandateId: string
  ): Promise<void> {
    await this.client.apiDelete(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveMandateRepository.SUB_OBJECTIVE_URL}/${subObjectiveId}`
    );
  }
  
  public async addObjectiveStateForMandate(
    addObjectiveState: AddObjectiveState,
    mandateId: string,
    objectiveId: string
  ): Promise<ObjectiveState> {
    const objectiveStateResponse = await this.client.apiPost<ObjectiveStateResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_STATE_HISTORY_URL}`,
      createAddObjectiveStateRequest(addObjectiveState)
    );
    return objectiveStateResponseToModel(objectiveStateResponse);
  }
  
  public async updateObjectiveStateCommentForMandate(
    updateObjectiveStateComment: UpdateObjectiveStateComment,
    coacheeId: string,
    objectiveId: string
  ): Promise<ObjectiveState> {
    const objectiveStateResponse = await this.client.apiPut<ObjectiveStateResponse>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${coacheeId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_STATE_HISTORY_URL}`,
      createUpdateObjectiveStateCommentRequest(updateObjectiveStateComment)
    )
    
    return objectiveStateResponseToModel(objectiveStateResponse);
  }
  
  public async fetchObjectiveStateHistory(mandateId: string, objectiveId: string): Promise<ObjectiveState[]> {
    const objectiveStateHistoryResponse = await this.client.apiGet<ObjectiveStateResponse[]>(
      `/${RESTObjectiveMandateRepository.BASE_URL}` +
      `/${RESTObjectiveMandateRepository.MANDATE_URL}/${mandateId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_URL}/${objectiveId}` +
      `/${RESTObjectiveMandateRepository.OBJECTIVE_STATE_HISTORY_URL}`
    );
    return objectiveStatesResponseToModel(objectiveStateHistoryResponse)
  }
}
