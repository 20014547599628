import React from 'react';
import styles from "module/mandate/view/asset/edit/MandateSidebarComponent.module.scss";
import Mandate from "module/mandate/domain/model/Mandate";
import MandateDeadlineEdit from "module/mandate/view/component/edit/MandateDeadlineEdit";
import MandateDeleteCloseSidebar from "module/mandate/view/component/edit/MandateDeleteCloseSidebar";
import MandateSidebarInfo from "module/mandate/view/component/edit/MandateSidebarInfo";
import {Moment} from "moment/moment";
import {ErrorNames} from "core/error/ErrorNames";
import {Alert} from "asset/component";
import {CircularProgress} from "@mui/material";

const MandateSidebarComponent = ({
  mandate,
  isSidebarOpen = false,
  close,
  updateMandate,
  deleteMandate,
  objectives,
  linkedOrgs,
  isLoading,
  errorNames
}: {
  mandate?: Mandate | null,
  isSidebarOpen?: boolean,
  close: () => void,
  updateMandate: (mandate: Mandate) => void,
  deleteMandate: () => void,
  objectives: JSX.Element,
  linkedOrgs: JSX.Element,
  isLoading?: boolean,
  errorNames?: ErrorNames
}) => {
  const updateMandateDate = (date: Moment | null) => {
    if (!date) return;
    if (!mandate) return;
    
    const updatedMandate = new Mandate(mandate?.id, mandate?.title, mandate?.description, new Date(date.toString()));
    updateMandate(updatedMandate);
  };
  
  const updateMandateInfo = (title: string, description: string) => {
    if (!mandate) return;
    
    const updatedMandate = new Mandate(mandate?.id, title, description, new Date(mandate?.deadline));
    updateMandate(updatedMandate);
  };
  
  return (
    <>
      {errorNames ?
        <Alert severity="error" data-testid="alert">{errorNames}</Alert> : <></>}
      {isLoading ? <CircularProgress /> :
        <div className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ""}`}
             data-testid="mandate-sidebar-component-container">
          <MandateDeleteCloseSidebar close={close} deleteMandate={deleteMandate} />
          <MandateDeadlineEdit mandate={mandate} setDeadline={(date) => updateMandateDate(date)} />
          <MandateSidebarInfo mandate={mandate}
                              updateInfo={(title, description) => updateMandateInfo(title, description)} />
          {objectives}
          {linkedOrgs}
        </div>}
    </>
  );
};

export default MandateSidebarComponent;
