import React from "react";
import UpdateCoacheeNamesFormComponent
  from "module/coachee/view/component/updateCoachee/UpdateCoacheeNamesFormComponent";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import UpdateCoacheeOrganisationFormComponent
  from "module/coachee/view/component/updateCoachee/UpdateCoacheeOrganisationFormComponent";
import UpdateCoacheeTeamFormComponent from "module/coachee/view/component/updateCoachee/UpdateCoacheeTeamFormComponent";
import {Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "module/coachee/view/asset/UpdateCoacheeFormComponent.module.scss";

export interface UpdateCoacheeStateProps {
  firstName: string;
  setFirstName: ((firstName: string) => void);
  lastName: string;
  setLastName: ((lastName: string) => void);
  teamId: string;
  setTeamId: ((teamId: string) => void);
  companyId: string;
  setCompanyId: ((companyId: string) => void);
  currentCompanyId?: string;
  currentTeamId?: string;
}

export interface UpdateCoacheeFormComponentProps {
  updateCoacheeState: UpdateCoacheeStateProps;
  companyOptions: InputOption[];
  teamOptions: InputOption[];
}

const UpdateCoacheeFormComponent = ({
  companyOptions,
  teamOptions,
  updateCoacheeState
}: UpdateCoacheeFormComponentProps) => {
  const {t} = useTranslation('coachee');
  const palette = usePaletteColors();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setTeamId,
    setCompanyId,
    currentCompanyId,
    currentTeamId
  } = updateCoacheeState;
  
  return <div className={styles.updateCoacheeFormComponent}>
    <Typography component="p" variant={MuiTypographyVariants.H4} color={palette.secondary.dark}>
      {t('form.update.title')}
    </Typography>
    <UpdateCoacheeNamesFormComponent firstName={firstName}
                                     setFirstName={setFirstName}
                                     lastName={lastName}
                                     setLastName={setLastName}
    />
    <UpdateCoacheeOrganisationFormComponent data-testid="company-form"
                                            companyOptions={companyOptions}
                                            companyId={currentCompanyId}
                                            setCompanyId={setCompanyId}
    />
    <UpdateCoacheeTeamFormComponent data-testid="team-form"
                                    teamOptions={teamOptions}
                                    teamId={currentTeamId}
                                    setTeamId={setTeamId}
    />
  </div>;
};

export default UpdateCoacheeFormComponent;
