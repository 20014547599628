import React, {useEffect, useState} from "react";
import Coachee from "module/coachee/domain/model/Coachee";
import UpdateDialog from "asset/component/global/dialogs/UpdateDialog";
import UpdateCoacheeFormComponent, {
  UpdateCoacheeStateProps
} from "module/coachee/view/component/UpdateCoacheeFormComponent";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {Routes} from "router/Routes";
import UpdateCoachee from "module/coachee/domain/model/UpdateCoachee";
import {useNavigate} from "react-router-dom";

const UpdateCoacheeDialogContainer = ({
  coachee
}: {
  coachee: Coachee
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  
  const [firstName, setFirstName] = useState(coachee.firstName);
  const [lastName, setLastName] = useState(coachee.lastName);
  const [teamId, setTeamId] = useState(coachee.teamIds[0] || '');
  const [companyId, setCompanyId] = useState(coachee.companyId);
  
  const [companyOptions, setCompanyOptions] = useState<InputOption[]>([]);
  const [teamOptions, setTeamOptions] = useState<InputOption[]>([]);
  
  const {companies} = useSelector(
    (rootState: RootState) => rootState.CompanyStore);
  const teams = useSelector(
    (rootState: RootState) => rootState.TeamStore?.getters(rootState)
      .getTeamsByCompanyId(companyId));
  
  const fetchTeamsByCompanyIdState = useLoadingAndError(
    () => dispatch.TeamStore.fetchTeamsByCompanyId(companyId),
    [companyId !== ""],
    companyId
  );
  
  const updateCoacheeState = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    teamId,
    setTeamId,
    companyId,
    setCompanyId,
    currentTeamId: teamId,
    currentCompanyId: companyId
  } as UpdateCoacheeStateProps;
  
  const [updateCoacheeLoading, UpdateCoacheeErrorName, updateCoacheeDispatch, isUpdateCompleted] =
    usePromiseLoadingAndError(() =>
      dispatch.CoacheeStore.updateCoachee(
        new UpdateCoachee(
          coachee.id,
          firstName,
          lastName,
          companyId,
          coachee.note,
          teamId
        )).then(() => {
        if (teamId) {
          navigate(Routes.coachee.profileTeam.path({coacheeId: coachee.id, teamId: teamId}));
        } else {
          navigate(Routes.coachee.profile.path({coacheeId: coachee.id}));
        }
      })
    );
  
  const [deleteCoacheeLoading, deleteCoacheeErrorName, deleteCoacheeDispatch] =
    usePromiseLoadingAndError(() =>
      dispatch.CoacheeStore.deleteCoachee({
        coacheeId: coachee.id,
      }).then(() => {
        navigate(Routes.home.path());
      })
    );
  
  useLoadingAndError(() => dispatch.CompanyStore.fetchCompanies());
  
  useEffect(() => {
    if (fetchTeamsByCompanyIdState.isCompleted) {
      setTeamOptions(teams.map(team => ({value: team.id, label: team.name})));
    }
  }, [fetchTeamsByCompanyIdState.isCompleted]);
  
  useEffect(() => {
    setCompanyOptions(companies.map(company => ({
      value: company.id,
      label: company.name
    })));
  }, [companies]);
  
  return (
    <UpdateDialog updateAction={updateCoacheeDispatch}
                  errorName={UpdateCoacheeErrorName || deleteCoacheeErrorName}
                  isLoading={updateCoacheeLoading}
                  isCompleted={isUpdateCompleted}
                  deleteAction={deleteCoacheeDispatch}
    >
      <UpdateCoacheeFormComponent
        updateCoacheeState={updateCoacheeState}
        companyOptions={companyOptions}
        teamOptions={teamOptions}
      />
    </UpdateDialog>
  );
};

export default UpdateCoacheeDialogContainer;
