import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import ChatBubble from "asset/icon/ChatBubble";
import {Typography} from "@mui/material";
import React from "react";

const ObjectiveStateHistoryComment = ({
  comment
} : {
  comment: string
}) => {
  
  return <div className={styles.individualComment}>
    <ChatBubble/>
    <Typography>{comment}</Typography>
  </div>
}

export default ObjectiveStateHistoryComment
