import {ModuleSectionTitle} from "asset/component";
import {useTranslation} from "react-i18next";
import {Button, CircularProgress, Typography} from "@mui/material";
import styles from "module/objective/view/asset/ObjectiveStateComponent.module.scss";
import HistoryIcon from "asset/icon/objectiveState/HistoryIcon";
import React, {useState} from "react";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import ObjectiveStateSelectionComponent from "module/objective/view/component/ObjectiveStateSelectionComponent";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import ObjectiveStateCreationComponent from "module/objective/view/component/ObjectiveStateCreationComponent";
import DialogMessage from "asset/component/global/dialogs/DialogMessage";
import ObjectiveStateHistoryModal from "module/objective/view/component/ObjectiveStateHistoryModal";

const ObjectiveStateComponent = ({
  objectiveTitle,
  addObjectiveState,
  updateObjectiveState,
  fetchObjectiveStateHistory,
  isStateHistoryLoading
} : {
  objectiveTitle:string
  addObjectiveState?: (status:string) => void,
  updateObjectiveState?: (comment:string) => void,
  fetchObjectiveStateHistory?: () => void,
  isStateHistoryLoading?: boolean
}) => {
  const {t} = useTranslation("objective");
  const [selectedStatus, setSelectedStatus] = React.useState<StateStatus>(StateStatus.NONE);
  const [isHistoryModalOpen, setHistoryModal] = useState(false);
  
  const onStatusClick = (status: StateStatus) => {
    addObjectiveState && addObjectiveState(status);
    setSelectedStatus(status);
  };
  
  const closeCreation = () => {
    setSelectedStatus(StateStatus.NONE);
  }
  
  const openHistoryModal = () => {
    fetchObjectiveStateHistory && fetchObjectiveStateHistory()
    setHistoryModal(true);
  }
  
  return (
    <div className={styles.container}>
      <ModuleSectionTitle title={t('objectiveStateTitle')}/>
      <Typography>{t('objectiveStateSubtitle')}</Typography>
      <div className={styles.stateCreation}>
        {
          selectedStatus === StateStatus.NONE?
            <ObjectiveStateSelectionComponent onStatusClick={onStatusClick}/> :
            <ObjectiveStateCreationComponent
              status={selectedStatus}
              onClose={closeCreation}
              onSave={updateObjectiveState} />
        }
      </div>
      <Button
        className={styles.HistoryButton}
        startIcon={<HistoryIcon/>}
        variant={MuiButtonVariants.CONTAINED}
        size={MuiButtonSizes.SMALL}
        onClick={openHistoryModal}>
        {t("progress")}
      </Button>
      <DialogMessage
        open={isHistoryModalOpen}
        setOpen={setHistoryModal}
        title={t("stateHistoryModal.title") + " \"" + objectiveTitle + "\""}
        text={t("stateHistoryModal.subtitle")}>
        {isStateHistoryLoading ? <CircularProgress/> : <ObjectiveStateHistoryModal />}
      </DialogMessage>
    </div>
  )
};

export default ObjectiveStateComponent;
