import UpdateCoachee from "module/coachee/domain/model/UpdateCoachee";

export default interface UpdateCoacheeRequest {
  firstName: string;
  lastName: string;
  companyId: string;
  note: string | undefined;
  teamId: string | undefined;
}

export function createUpdateCoacheeRequest(updateCoachee: UpdateCoachee): UpdateCoacheeRequest {
  const updateCoacheeRequest = {
    firstName: updateCoachee.firstName,
    lastName: updateCoachee.lastName,
    companyId: updateCoachee.companyId,
    note: updateCoachee.note
  } as UpdateCoacheeRequest;
  
  if (updateCoachee.teamId) updateCoacheeRequest.teamId = updateCoachee.teamId;
  
  return updateCoacheeRequest;
}
