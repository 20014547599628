import React from 'react';

const ChatBubble = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.667 1.66675H3.33366C2.41699 1.66675 1.66699 2.41675 1.66699 3.33341V18.3334L5.00033 15.0001H16.667C17.5837 15.0001 18.3337 14.2501 18.3337 13.3334V3.33341C18.3337 2.41675 17.5837 1.66675 16.667 1.66675ZM16.667 13.3334H5.00033L3.33366 15.0001V3.33341H16.667V13.3334Z"
            fill="#123029" />
    </svg>
  )
}

export default ChatBubble
