export interface IMandate {
  id: string;
  title: string;
  description: string;
  deadline: Date;
}

export default class Mandate implements IMandate {
  private readonly _id: string;
  private readonly _title: string;
  private readonly _description: string;
  private readonly _deadline: Date;
  private readonly _companies: string[];
  private readonly _teams: string[];
  private readonly _coachees: string[];
  
  constructor(
    id: string,
    title: string,
    description: string,
    deadline: Date,
    companies?: string[],
    teams?: string[],
    coachees?: string[]
  ) {
    this._id = id;
    this._title = title;
    this._description = description;
    this._deadline = deadline;
    this._companies = companies || [];
    this._teams = teams || [];
    this._coachees = coachees || [];
  }
  
  get id(): string {
    return this._id;
  }
  
  get title(): string {
    return this._title;
  }
  
  get description(): string {
    return this._description;
  }
  
  get deadline(): Date {
    return this._deadline;
  }
  
  get companies(): string[] {
    return this._companies;
  }
  
  get teams(): string[] {
    return this._teams;
  }
  
  get coachees(): string[] {
    return this._coachees;
  }
}
