export default class UpdateCoachee {
  private readonly _id: string;
  private readonly _firstName: string;
  private readonly _lastName: string;
  private readonly _companyId: string;
  private readonly _note: string;
  private readonly _teamId?: string;
  
  constructor(
    id: string,
    firstName: string,
    lastName: string,
    companyId: string,
    note: string,
    teamId?: string
  ) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
    this._companyId = companyId;
    this._teamId = teamId;
    this._note = note;
  }
  
  get id(): string {
    return this._id;
  }
  
  get firstName(): string {
    return this._firstName;
  }
  
  get lastName(): string {
    return this._lastName;
  }
  
  get teamId(): string | undefined {
    return this._teamId;
  }
  
  get companyId(): string {
    return this._companyId;
  }
  
  get note(): string {
    return this._note;
  }
}
