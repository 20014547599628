import {StateStatus} from "module/objective/domain/model/StateStatus";

export default class ObjectiveState {
  private readonly _id: string;
  private readonly _comment: string;
  private readonly _status: StateStatus;
  private readonly _createdAt: Date;
  
  constructor(
    id: string,
    comment: string,
    status: StateStatus,
    createdAt: string
  ) {
    this._id = id;
    this._comment = comment;
    this._status = status;
    this._createdAt = new Date(createdAt);
  }
  
  get status(): StateStatus {
    return this._status;
  }
  
  get createdAt(): Date {
    return this._createdAt;
  }
  
  get comment(): string {
    return this._comment;
  }
}
